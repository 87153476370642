
import Editor from "./Editor";
import "./App.css";

function App() {
  return (
    
      <div className="App">
        <Editor />
      </div>
    
  );
}

export default App;
