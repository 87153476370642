import { useCallback, useEffect, useRef, useState } from 'react';

// react-pintura
import { PinturaEditor } from '@pqina/react-pintura';

// pintura
import '@pqina/pintura/pintura.css';
import {
    // editor
    // locale_en_gb,
    // createDefaultImageReader,
    // createDefaultImageWriter,
    // createDefaultShapePreprocessor,

    // plugins
    setPlugins,
    plugin_crop,
    // plugin_crop_locale_en_gb,
    plugin_finetune,
    // plugin_finetune_locale_en_gb,
    // plugin_finetune_defaults,
    plugin_filter,
    // plugin_filter_locale_en_gb,
    // plugin_filter_defaults,
    plugin_annotate,
    // plugin_annotate_locale_en_gb,
    // markup_editor_defaults,
    // markup_editor_locale_en_gb,
    getEditorDefaults
    
} from '@pqina/pintura';
const editorConfig = getEditorDefaults({ enableButtonExport:false});
setPlugins(plugin_crop, plugin_finetune, plugin_filter, plugin_annotate);

// const editorDefaults = {
//     imageReader: createDefaultImageReader(),
//     imageWriter: createDefaultImageWriter(),
//     shapePreprocessor: createDefaultShapePreprocessor(),
//     ...plugin_finetune_defaults,
//     ...plugin_filter_defaults,
//     ...markup_editor_defaults,
//     locale: {
//         ...locale_en_gb,
//         ...plugin_crop_locale_en_gb,
//         ...plugin_finetune_locale_en_gb,
//         ...plugin_filter_locale_en_gb,
//         ...plugin_annotate_locale_en_gb,
//         ...markup_editor_locale_en_gb,
//     },
// };
interface SizeProps {
    width: number;
    height: number;

}
export default function Editor() {
    // inline
    const [data, setData] = useState<any>(null);
    const [ready, setReady] = useState(false);
    const editorRef = useRef<PinturaEditor>(null);
    // console.log('editorConfig',editorConfig);
    const [config, setConfig] = useState<any>(editorConfig);
    const postMessageBack = useCallback((dt : Object)=>{
        if(window.parent !== window){
            window.parent.postMessage(dt, '*');
        }
    },[]);
    const onMessage = useCallback((e: MessageEvent) => {
        if (e.data.action === 'image-editor-init') {
            setReady(true);
            setData(e.data.data);
            if(e.data.data?.mode === 'crop' && e.data.data.size && e.data.data.size)
            {
                let size: SizeProps = e.data.data.size;
                setConfig({...editorConfig,enableButtonExport:false,imageTargetSize:size, imageCropSize: size, imageCropAspectRatio:size.width/size.height});
            }
        }   
        else if(e.data.action === 'image-editor-save'){
            if(editorRef.current)
            {
                editorRef.current.editor.processImage().then((imageWriterResult) => {
                    // Logs resulting image
                    postMessageBack({
                        action: 'image-editor-save',
                        data: imageWriterResult
                    });
                });
            }
        }
    },[postMessageBack]);
    useEffect(() => {
        window.addEventListener('message', onMessage);
        postMessageBack({
            action: 'image-editor-ready',
            data: {}
        });
        // setTimeout(()=>{
        //     let size: SizeProps = {width: 600, height: 900}
        //     setConfig({...editorConfig,imageTargetSize:size, imageCropSize: size, imageCropAspectRatio:size.width/size.height});
        //     setData({src: 'https://cdn.shopify.com/s/files/1/0629/4392/0358/files/S3b44f6830e2b43e5b88ef67f4a03ad06l.jpg?v=1684813483'})
        //     setReady(true);
        // },2000);
       
        return () => {
            window.removeEventListener('message', onMessage);
        }
        

    },[onMessage,postMessageBack]);
    return (
        <div className="App">
            <div style={{ minHeight: '90vh',height:'90vh' }}>
                { ready ? <PinturaEditor
                
                    {...config}
                    
                    ref={editorRef}
                    src={data.src}
                    onLoad={(res: any) => {
                        // console.log('Did load image', res);

                        // not yet set
                        if (!editorRef.current) return;

                        // Example using editor ref
                        // const { editor } = editorRef.current;

                        // Now we can access properties and methods
                        // editor.imageCropAspectRatio = 1;
                        postMessageBack({
                            action: 'image-editor-loaded',
                            data: {}
                        });

                    }}
                    onProcess={({ dest }: any) => {
                        // console.log(dest);
                         
                        const img = new Image();
                        img.onload = function(e) {
                        //  console.log(img.width,img.height);
                        }
                        img.src = URL.createObjectURL(dest);
                        postMessageBack({
                            action: 'image-editor-save',
                            data: dest
                        });
                    }}
                />: ''}
            </div>
        </div>
    );
}
